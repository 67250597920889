/**
 * @generated SignedSource<<35e29a404e173d77803b9b167b024983>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginLogoutInput = {
  clientMutationId?: string | null;
};
export type LogoutMutation$variables = {
  input: LoginLogoutInput;
};
export type LogoutMutation$data = {
  readonly loginLogout: {
    readonly success: boolean;
  };
};
export type LogoutMutation = {
  response: LogoutMutation$data;
  variables: LogoutMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LoginLogoutPayload",
    "kind": "LinkedField",
    "name": "loginLogout",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LogoutMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LogoutMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6d496cdb9866ecf4ce4427d2f242b267",
    "id": null,
    "metadata": {},
    "name": "LogoutMutation",
    "operationKind": "mutation",
    "text": "mutation LogoutMutation(\n  $input: LoginLogoutInput!\n) {\n  loginLogout(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f8e092c1ce5ecee9f42fbef05ecd18e";

export default node;
